import { generatePageUrl } from "./utils";

export const BASE_URL = "/";
export const PRODUCTS_PER_PAGE = 6;
export const SUPPORT_EMAIL = " pedidos@casadeluniformeqro.com";
export const PROVIDERS = {
  BRAINTREE: {
    label: "Braintree",
  },
  DUMMY: {
    label: "Dummy",
  },
  STRIPE: {
    href: "https://js.stripe.com/v3/",
    label: "Stripe",
  },
};
export const STATIC_PAGES = [
  {
    label: "¿ Quiénes Somos ?",
    url: generatePageUrl("about"),
  },
  {
  label: "Nuestros Clientes",
    url: generatePageUrl("clients"),

  },
  {
   label: "Contacto",
    url: generatePageUrl("contact"),
    },
    {
   label: "Aviso De Privacidad",
    url: generatePageUrl("aviso-de-privacidad"),
    },
    {
    label: "Política de Cambios",
    url: generatePageUrl("politica-de-cambios"),
    },
    {
    label: "Términos y Condiciones",
    url: generatePageUrl("terminos-y-condiciones"),
    },
];
export const SOCIAL_MEDIA = [
  {
    ariaLabel: "maps",
    href: "https://goo.gl/maps/NWCHWh2kVo8QcRpA7",
    path: require("../images/maps-icon.svg"),
  },
  {
    ariaLabel: "whatsapp",
    href: "https://wa.me/524427061572",
    path: require("../images/whatsapp-icon.svg"),
  },
  {
    ariaLabel: "phone",
    href: "tel:524427061572",
    path: require("../images/phone-icon.svg"),
  },
  {
    ariaLabel: "email",
    href: "mailto:pedidos@casadeluniformeqro.com",
    path: require("../images/email-icon.svg"),
  },
];
export const META_DEFAULTS = {
  custom: [],
  description:
    "La Casa del Uniforme es una empresa mexicana con más de 15 años de experiencia, enfocados en la distribución y fabricación de prendas de vestir.",
  image: `${window.location.origin}${require("../images/logo.svg")}`,
  title: "La Casa Del Uniforme",
  type: "website",
  url: window.location.origin,
};
export enum CheckoutStep {
  Address = 1,
  Shipping,
  Payment,
  Review,
}
export const CHECKOUT_STEPS = [
  {
    index: 0,
    link: "/checkout/address",
    name: "Dirección",
    nextActionName: "Continuar a Envío",
    nextStepLink: "/checkout/shipping",
    onlyIfShippingRequired: true,
    step: CheckoutStep.Address,
  },
  {
    index: 1,
    link: "/checkout/shipping",
    name: "Envío",
    nextActionName: "Continuar al Pago",
    nextStepLink: "/checkout/payment",
    onlyIfShippingRequired: true,
    step: CheckoutStep.Shipping,
  },
  {
    index: 2,
    link: "/checkout/payment",
    name: "Pago",
    nextActionName: "Continuar a Revisar",
    nextStepLink: "/checkout/review",
    onlyIfShippingRequired: false,
    step: CheckoutStep.Payment,
  },
  {
    index: 3,
    link: "/checkout/review",
    name: "Revisar",
    nextActionName: "Realizar Pedido",
    nextStepLink: "/order-finalized",
    onlyIfShippingRequired: false,
    step: CheckoutStep.Review,
  },
];
