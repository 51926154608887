import React, { useEffect, useState } from "react";

import { Checkbox } from "@components/atoms";
import { filterNotEmptyArrayItems } from "@utils/misc";

import { AddressForm } from "../AddressForm";
import { AddressGridSelector } from "../AddressGridSelector";
import { DiscountForm } from "../DiscountForm";
import { IDiscountFormData } from "../DiscountForm/types";
import { PaymentGatewaysList } from "../PaymentGatewaysList";

import * as S from "./styles";
import { IProps } from "./types";

/**
 * Payment options used in checkout.
 */
const CheckoutPayment: React.FC<IProps> = ({
  gatewayErrors,
  billingErrors,
  promoCodeErrors,
  selectedUserAddressId,
  userAddresses,
  billingAsShippingAddress = true,
  checkoutBillingAddress,
  countries,
  billingFormRef,
  billingFormId,
  paymentGateways,
  setBillingAddress,
  billingAsShippingPossible,
  setBillingAsShippingAddress,
  promoCodeDiscountFormId,
  promoCodeDiscountFormRef,
  promoCodeDiscount,
  addPromoCode,
  removeVoucherCode,
  submitUnchangedDiscount,
  selectedPaymentGateway,
  selectedPaymentGatewayToken,
  selectPaymentGateway,
  gatewayFormRef,
  gatewayFormId,
  userId,
  newAddressFormId,
  processPayment,
  onGatewayError,
}: IProps) => {
  const [showPromoCodeForm, setShowPromoCodeForm] = useState(
    !!promoCodeDiscount?.voucherCode
  );
  // const [askInvoice, setAskInvoiceForm] = useState(
  //   false
  // );

  useEffect(() => {
    const isVoucherCode = !!promoCodeDiscount?.voucherCode;
    if (isVoucherCode) {
      setShowPromoCodeForm(isVoucherCode);
    }
  }, [promoCodeDiscount?.voucherCode]);

  const handleChangeShowPromoCodeForm = () => {
    setShowPromoCodeForm(!showPromoCodeForm);
  };
  // const handleChangeAskInvoice = () => {
  //   setAskInvoiceForm(!askInvoice);
  // };

  const handleSubmitPromoCode = (discountForm?: IDiscountFormData) => {
    const newPromoCode = discountForm?.promoCode;
    const savedPromoCode = promoCodeDiscount?.voucherCode;

    if ((!newPromoCode || !showPromoCodeForm) && savedPromoCode) {
      removeVoucherCode(savedPromoCode);
    } else if (newPromoCode && newPromoCode !== savedPromoCode) {
      addPromoCode(newPromoCode);
    } else {
      submitUnchangedDiscount();
    }
  };

  const adresses =
    userAddresses?.filter(filterNotEmptyArrayItems).map(address => ({
      address: {
        ...address,
        isDefaultBillingAddress: address.isDefaultBillingAddress || false,
        isDefaultShippingAddress: address.isDefaultShippingAddress || false,
        phone: address.phone || undefined,
      },
      id: address?.id || "",
      onSelect: () => null,
    })) || [];

  return (
    <S.Wrapper>
        <S.Title data-cy="checkoutPageSubtitle">Facturación</S.Title>
          En caso de requerir factura, por favor enviar su información de facturación a pedidos@casadeluniformeqro.com

          <S.Divider />
        <section hidden={true}>

        {billingAsShippingPossible && (
          <Checkbox
            data-cy="checkoutPaymentBillingAsShippingCheckbox"
            name="billing-same-as-shipping"
            checked={billingAsShippingAddress}
            onChange={() =>
              setBillingAsShippingAddress(!billingAsShippingAddress)
            }
          >
            Usar la Dirección de Envío
          </Checkbox>
        )}
        {!billingAsShippingAddress && (
          <>
            {billingAsShippingPossible && <S.Divider />}
            {userAddresses ? (
              <AddressGridSelector
                formId={billingFormId}
                formRef={billingFormRef}
                addresses={adresses}
                selectedAddressId={selectedUserAddressId}
                countriesOptions={countries?.filter(filterNotEmptyArrayItems)}
                userId={userId}
                errors={billingErrors}
                onSelect={(address, id) =>
                  setBillingAddress(address, undefined, id)
                }
                newAddressFormId={newAddressFormId}
              />
            ) : (
              <AddressForm
                formId={billingFormId}
                formRef={billingFormRef}
                countriesOptions={countries.filter(filterNotEmptyArrayItems)}
                address={checkoutBillingAddress || undefined}
                handleSubmit={address =>
                  setBillingAddress(address, address?.email)
                }
                includeEmail={!billingAsShippingPossible}
                errors={billingErrors}
              />
            )}
          </>
        )}
      </section>
      <section>
        <S.Title data-cy="checkoutPageSubtitle">Método de Pago</S.Title>
        <Checkbox
          data-cy="checkoutPaymentPromoCodeCheckbox"
          name="payment-promo-code"
          checked={showPromoCodeForm}
          onChange={handleChangeShowPromoCodeForm}
        >
          Tienes algúna tarjeta de regalo o algún código de descuento?
        </Checkbox>
        {showPromoCodeForm && (
          <S.DiscountField>
            <DiscountForm
              discount={{ promoCode: promoCodeDiscount?.voucherCode }}
              formId={promoCodeDiscountFormId}
              formRef={promoCodeDiscountFormRef}
              handleSubmit={handleSubmitPromoCode}
              errors={promoCodeErrors}
            />
          </S.DiscountField>
        )}
        <S.Divider />
        <PaymentGatewaysList
          errors={gatewayErrors}
          paymentGateways={paymentGateways}
          formRef={gatewayFormRef}
          formId={gatewayFormId}
          processPayment={processPayment}
          selectedPaymentGateway={selectedPaymentGateway}
          selectedPaymentGatewayToken={selectedPaymentGatewayToken}
          selectPaymentGateway={selectPaymentGateway}
          onError={onGatewayError}
        />
      </section>
    </S.Wrapper>
  );
};

export { CheckoutPayment };
