import * as React from "react";

export const NothingFound: React.FC<{ search: string }> = ({ search }) => (
  <div className="search__products--not-found">
    <p className="u-lead u-lead--bold u-uppercase">
      Lo lamentamos, pero no pudimos encontrar algún resultado para: {search}
    </p>
    <p>
      Checa la ortografía, piensa en algo menos específico y vuelve a buscar.
    </p>
  </div>
);

export default NothingFound;
