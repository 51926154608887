import "./scss/index.scss";

import * as React from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../core/config";
import Button from "../Button";

interface NotFoundProps {
  message?: string;
}

const NotFound: React.FC<NotFoundProps> = () => (
  <div className="not-found-page">
    <h2 className="not-found-page__header">404</h2>
    <div className="not-found-page__ruler" />
    <div className="not-found-page__message">
      <p>No hemos encontrado la página que andabas buscando!</p>
      <p>Podría ser un error en la direccón de internet, o la página ya no existe. </p>
      <p>Lamentamos el error y esperamos que puedas encontrar lo que buscas.</p>
    </div>
    <div className="not-found-page__button">
      <Link to={BASE_URL}>
        <Button secondary>Regresar</Button>
      </Link>
    </div>
  </div>
);

export default NotFound;
