import React from "react";

import { ErrorMessage } from "@components/atoms";
import { AddressSummary } from "@components/molecules";

import * as S from "./styles";
import { IProps } from "./types";

/**
 * Review order view showed in checkout.
 */
const CheckoutReview: React.FC<IProps> = ({
  shippingAddress,
  billingAddress,
  shippingMethodName,
  paymentMethodName,
  email,
  errors,
}: IProps)=> {
  const errtext=errors;
  if(errors){
    if(errtext[0]){
      if(errtext[0].message){
        errtext[0].message="Su tarjeta ha sido declinada";
      }
    }
  }
  return (
    <S.Wrapper>
      <S.Title data-cy="checkoutPageSubtitle">Revisar Pedido</S.Title>
      <S.SubTitle data-cy="checkoutPageTerms">
            La entrega de su pedido se realizará dentro de 4 semanas hábiles a partir de la fecha de pago. Al realizar su compra en línea acepta los términos y condiciones de compra, al igual que nuestra política de cambios; los cuáles puede encontrar aquí:
      </S.SubTitle>
          <S.Link
          to={"/page/politica-de-cambios/"}
          fullWidth={false}
            >
          Política de Cambios
        </S.Link>
        <br></br>
        <br></br>
        <S.Link
          to={"/page/terminos-y-condiciones/"}
          fullWidth={false}
            >
          Terminos y Condiciones
        </S.Link>
        <br></br>
        <br></br>
      <S.Grid>
        <section>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">
            Dirección de Envío
          </S.SubTitle>
          <S.Divider />
          <AddressSummary address={shippingAddress} email={email} />
        </section>
        <section hidden={true}>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">
            Dirección de Facturación
          </S.SubTitle>
          <S.Divider />
          <AddressSummary address={billingAddress} email={email} />
        </section>
        <section>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">
            Método de Envío
          </S.SubTitle>
          <S.Divider />
          <S.TextSummary>{shippingMethodName}</S.TextSummary>
        </section>
        <section>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">
            Método de Pago
          </S.SubTitle>
          <S.Divider />
          <S.TextSummary>{paymentMethodName}</S.TextSummary>
        </section>
      </S.Grid>
      <S.ErrorMessages>
        <ErrorMessage errors={errtext} />
      </S.ErrorMessages>
    </S.Wrapper>
  );
};

export { CheckoutReview };
