import React from "react";
import { Link } from "react-router-dom";

import * as S from "./styles";
import { IProps } from "./types";

export const AccountMenu: React.FC<IProps> = ({ links, active }: IProps) => {
  return (
    <S.Wrapper>
      <S.MenuHeader>Mi Cuenta</S.MenuHeader>
      {links.map(link => {

        let menuItem;
        if (link.includes("account")){
          menuItem="Mi Cuenta";
        }
        else if (link.includes("order")){
          menuItem="Mis Pedidos";
        }
        else{
          menuItem="Mis Direcciones";
        }

        return (
          <Link to={link} key={link} data-testid="account_menu__link">
            <S.MenuItem active={active === link}>{menuItem}</S.MenuItem>
          </Link>
        );
      })}
    </S.Wrapper>
  );
};
