import React, { useCallback } from "react";

import { InputSelect, TextField } from "@components/molecules";

import * as S from "./styles";
import { PropsWithFormik } from "./types";

export const AddressFormContent: React.FC<PropsWithFormik> = ({
  formRef,
  handleChange,
  handleBlur,
  formId,
  errors,
  handleSubmit,
  values,
  countriesOptions,
  defaultValue,
  setFieldValue,
  includeEmail = false,
}) => {
  const basicInputProps = useCallback(
    () => ({ onBlur: handleBlur, onChange: handleChange }),
    [handleChange, handleBlur]
  );
  const countryAreaOptions : any = [
      {label: "Aguascalientes",value: "Aguascalientes"},{label: "Baja California",value: "Baja California"},{label: "Baja California Sur",value: "Baja California Sur"},{label: "Campeche",value: "Campeche"},
      {label: "Chiapas",value: "Chiapas"},{label: "Ciudad de México",value: "Ciudad de México"},{label: "Chihuahua",value: "Chihuahua"},{label: "Coahuila",value: "Coah."},
      {label: "Colima",value: "Colima"},{label: "Durango",value: "Durango"},{label: "Guanajuato",value: "Guanajuato"},{label: "Guerrero",value: "Guerrero"},{label: "Hidalgo",value: "Hidalgo"},
      {label: "Jalisco",value: "Jalisco"},{label: "Estado de México",value: "Estado de México"},{label: "Michoacán",value: "Michoacán"},{label: "Morelos",value: "Morelos"},
      {label: "Nayarit",value: "Nayarit"},{label: "Nuevo León",value: "Nuevo León"},{label: "Oaxaca",value: "Oaxaca"},{label: "Puebla",value: "Puebla"},
      {label: "Querétaro",value: "Querétaro"},{label: "Quintana Roo",value: "Quintana Roo"},{label: "San Luis Potosí",value: "San Luis Potosí"},{label: "Sinaloa",value: "Sinaloa"},
      {label: "Sonora",value: "Sonora"},{label: "Tabasco",value: "Tabasco"},{label: "Tamaulipas",value: "Tamaulipas"},{label: "Tlaxcala",value: "Tlaxcala"},
      {label: "Veracruz",value: "Veracruz"},{label: "Yucatán",value: "Yucatán"},{label: "Zacatecas",value: "Zacatecas"}];

  const countriesOptionsMx : any = [{
      code: "MX",
      country: "México",
   }];

  const fieldErrors: any = {};
  let i=0;
  if (errors) {
    for (const error of errors){
      if (error.message==="This field is required."){
        errors[i].message="Campo Requerido"
        i++;
      }
    }
    errors.map(({ field, message }: { field: string; message: string }) => {
      fieldErrors[field] = fieldErrors[field]
        ? [...fieldErrors[field], { message }]
        : [{ message }];
    });
  }

  return (
    <S.AddressForm id={formId} ref={formRef} onSubmit={handleSubmit}>
      <S.Wrapper>
        <S.RowWithTwoCells>
          <TextField
            data-cy="addressFormFirstName"
            name="firstName"
            label="Nombre"
            value={values!.firstName}
            autoComplete="given-name"
            errors={fieldErrors!.firstName}
            {...basicInputProps()}
          />
          <TextField
            data-cy="addressFormLastName"
            name="lastName"
            label="Apellido"
            value={values!.lastName}
            autoComplete="family-name"
            errors={fieldErrors!.lastName}
            {...basicInputProps()}
          />
        </S.RowWithTwoCells>
        <S.RowWithTwoCells>
          <TextField
            data-cy="addressFormCompanyName"
            name="companyName"
            label="Compañia (Opcional)"
            value={values!.companyName}
            autoComplete="organization"
            errors={fieldErrors!.companyName}
            {...basicInputProps()}
          />
          <TextField
            data-cy="addressFormPhone"
            name="phone"
            label="Teléfono"
            value={values!.phone}
            autoComplete="tel"
            errors={fieldErrors!.phone}
            {...basicInputProps()}
          />
        </S.RowWithTwoCells>
        <S.RowWithOneCell>
          <TextField
            data-cy="addressFormStreetAddress1"
            name="streetAddress1"
            label="Dirección"
            value={values!.streetAddress1}
            autoComplete="address-line1"
            errors={fieldErrors!.streetAddress1}
            {...basicInputProps()}
          />
        </S.RowWithOneCell>
        <S.RowWithOneCell>
          <TextField
            data-cy="addressFormStreetAddress2"
            name="streetAddress2"
            label="Dirección adicional"
            value={values!.streetAddress2}
            autoComplete="address-line2"
            errors={fieldErrors!.streetAddress2}
            {...basicInputProps()}
          />
        </S.RowWithOneCell>
        <S.RowWithTwoCells>
          <TextField
            data-cy="addressFormCity"
            name="city"
            label="Ciudad"
            value={values!.city}
            autoComplete="address-level1"
            errors={fieldErrors!.city}
            {...basicInputProps()}
          />
          <TextField
            data-cy="addressFormPostalCode"
            name="postalCode"
            label="Código Postal"
            value={values!.postalCode}
            autoComplete="postal-code"
            errors={fieldErrors!.postalCode}
            {...basicInputProps()}
          />
        </S.RowWithTwoCells>
        <S.RowWithTwoCells>
          <InputSelect
            inputProps={{
              "data-cy": "addressFormCountry",
            }}
            defaultValue={defaultValue}
            label="País"
            name="country"
            options={countriesOptionsMx}
            value={
              values!.country &&
              countriesOptions &&
              countriesOptions!.find(
                option => option.code === values!.country!.code
              )
            }
            onChange={(value: any, name: any) => setFieldValue(name, value)}
            optionLabelKey="country"
            optionValueKey="code"
            errors={fieldErrors!.country}
          />
          <InputSelect
            inputProps={{
              "data-cy": "addressFormCountryArea",
            }}
            defaultValue={defaultValue}
            name="countryArea"
            label="Estado"
            options={ countryAreaOptions}
            value={values!.countryArea &&
              countryAreaOptions &&
              countryAreaOptions!.find(
                option => option.value === values!.countryArea
              )
            }
            onChange={(value: any, name: any) => setFieldValue(name, value.value)}
            optionLabelKey="label"
            optionValueKey="value"
            errors={fieldErrors!.countryArea}
          />
        </S.RowWithTwoCells>
        {includeEmail && (
          <S.RowWithTwoCells>
            <TextField
              data-cy="addressFormEmail"
              name="email"
              label="Email"
              value={values!.email}
              autoComplete="email"
              errors={fieldErrors!.email}
              {...basicInputProps()}
            />
          </S.RowWithTwoCells>
        )}
      </S.Wrapper>
    </S.AddressForm>
  );
};
