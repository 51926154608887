import React from "react";

import { Button } from "@components/atoms";
import { Container } from "@components/templates";

import * as S from "./styles";
import { IProps } from "./types";

/**
 * Thank you page after completing the checkout.
 */
const ThankYou: React.FC<IProps> = ({
  orderNumber,
  continueShopping,
  orderDetails,
}: IProps) => {
  return (
    <Container>
      <S.Wrapper>
        <S.ThankYouHeader>
          Gracias
          <br />
          <span>por tu pedido!</span>
        </S.ThankYouHeader>
        <S.Paragraph>
          Tu número de pedido es <span>{orderNumber}</span>
        </S.Paragraph>
        <S.Paragraph>
          Te hemos enviado un email de confirmación, y te notificaremos tan pronto tu pedido se envíe.
        </S.Paragraph>
        <S.Buttons>
          <Button onClick={continueShopping} color="secondary" fullWidth={true}>
            Continuar Comprando
          </Button>
          <Button onClick={orderDetails} fullWidth={true}>
            Detalles del Pedido
          </Button>
        </S.Buttons>
      </S.Wrapper>
    </Container>
  );
};

export { ThankYou };
