import React from "react";

import { Link } from "react-router-dom";
import { Button, OverlayTheme, OverlayType } from "..";
import { OverlayContextInterface } from "../Overlay";

const CheckoutAsGuest: React.FC<{
  overlay: OverlayContextInterface;
  checkoutUrl: string;
}> = ({ overlay, checkoutUrl }) => (
  <div className="checkout-login__guest">
    <h3 className="checkout__header">Continuar como Invitado</h3>
    <p>
      Si no quieres registrarte, no hay ningún problema. Puedes hacer checkout como Invitado.
    </p>
    <Link to={checkoutUrl}>
      <Button>Continuar como Invitado</Button>
    </Link>

    <p>
      o puedes {" "}
      <span
        className="u-link"
        onClick={() => overlay.show(OverlayType.register, OverlayTheme.right)}
      >
        crear una cuenta
      </span>
    </p>
  </div>
);

export default CheckoutAsGuest;
